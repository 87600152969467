import React from "react"
import { graphql, StaticQuery } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import "../utils/normalize.css"
import "../utils/css/screen.css"
// import Face from "../../content/assets/unlimish_face.webp"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="About"
        keywords={[
          `creator`,
          `unlimish`,
          `portfolio`,
          `motion graphics`,
          `design`,
        ]}
      />

      <Helmet>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Helmet>
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <figure alt="Unlimish's REEL 2023" className="kg-image-card">
            <div className="vimeo-div">
              <iframe
                className="vimeo-em"
                src="https://player.vimeo.com/video/833999433?h=ec399873a6"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="Unlimish REEL 2023"
              ></iframe>
            </div>
          </figure>
          <h2 id="clean-minimal-and-deeply-customisable-london-is-a-theme-made-for-people-who-appreciate-simple-lines-">
            Unlimish / あんりみ
          </h2>
          <p align="center">design / code / illustration / video / ..</p>
          {/* <figure className="kg-card kg-image-card">
			<Img
			  fluid={data.unlimishPic.childImageSharp.fluid}
			  className="kg-image"
			  loading="eager"
			  durationFadeIn={100}
			/>
		  </figure> */}
          <h3>Contact / Request</h3>
          <div align="center">
            <a
              href="#mailgo"
              className="button primary large"
              data-address="work"
              data-domain="unlimi.sh"
            >
              Mail
            </a>
          </div>
          <h3>Tools</h3>
          <div className="row">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  // background: "#fff3e0",
                }}
              >
                Illustrator
              </div>
            </div>

            <div className="col">
              <div
                className="col-inner"
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  // background: "#def6fc",
                }}
              >
                Photoshop
              </div>
            </div>

            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  // background: "#f2e0ff",
                }}
              >
                After Effects
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  // background: "#e6f2ff",
                }}
              >
                DaVinci Resolve
              </div>
            </div>

            <div className="col">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  // background: "#ffede0",
                }}
              >
                Blender
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    unlimishPic: file(relativePath: { eq: "unlimish_face.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
